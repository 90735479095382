import { useState, ChangeEvent } from 'react';
import {
  Button,
  Card,
  TextField,
  Typography,
  IconButton,
  Box,
  CircularProgress,
} from '@mui/material';
import { PhotoCamera, VideoCall, Delete, Cancel } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { createPost } from '../../redux/slices/posts/posts.thunks';
import UserProfile from '../UserProfile';

interface Auth {
  id: number;
  firstName: string;
  lastName: string;
  perms: { permission: string }[];
}

export default function AddMedia() {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth) as Auth | null;

  const [content, setContent] = useState<string>('');
  const [photos, setPhotos] = useState<File[]>([]);
  const [video, setVideo] = useState<File | null>(null);
  const [videoPreview, setVideoPreview] = useState<string | null>(null);
  const [isPosting, setIsPosting] = useState<boolean>(false);

  function checkHasPermission(permission: string): boolean {
    return !!auth?.perms?.some((p) => p.permission === permission);
  }

  const hasCreateMedia = checkHasPermission('CREATE_MEDIA');
  const isPhotoUploadDisabled = video !== null;
  const isVideoUploadDisabled = photos.length > 0;

  const handlePhotoChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (video) {
      alert('You cannot upload photos when a video is selected.');
      return;
    }
    const files = Array.from(event.target.files || []).filter((file) =>
      file.type.startsWith('image/')
    );
    const uniqueFiles = files.filter(
      (file) => !photos.some((p) => p.name === file.name)
    );

    if (uniqueFiles.length + photos.length > 10) {
      alert('You can only upload up to 10 photos.');
      return;
    }

    setPhotos((prev) => [...prev, ...uniqueFiles]);
  };

  const handleVideoChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (photos.length > 0) {
      alert('You cannot upload a video when photos are selected.');
      return;
    }
    const file = event.target.files?.[0];
    if (file && file.type.startsWith('video/')) {
      setVideo(file);
      setVideoPreview(URL.createObjectURL(file));
    } else {
      alert('Please select a valid video file.');
    }
  };

  const removePhoto = (index: number) => {
    setPhotos((prev) => prev.filter((_, i) => i !== index));
  };

  const removeVideo = () => {
    if (videoPreview) {
      URL.revokeObjectURL(videoPreview);
    }
    setVideo(null);
    setVideoPreview(null);
    const videoInput = document.getElementById('video-upload') as HTMLInputElement;
    if (videoInput) videoInput.value = '';
  };

  const cancelPost = () => {
    setContent('');
    setPhotos([]);
    setVideo(null);
    setVideoPreview(null);
  };

  const handleSubmit = () => {
    // Avoid posting if there's nothing to post
    if (!content.trim() && photos.length === 0 && !video) return;

    setIsPosting(true);

    const formData = new FormData();
    if (auth?.id) {
      formData.append('userId', auth.id.toString());
    }
    formData.append('content', content);

    photos.forEach((file) => {
      formData.append('photos', file);
    });

    if (video) {
      formData.append('s3video', video);
    }

    dispatch(createPost(formData))
      .then(() => {
        cancelPost();
        setIsPosting(false);
        // Optionally navigate to another page here
      })
      .catch(() => {
        setIsPosting(false);
      });
  };

  if (!hasCreateMedia) return null;

  return (
    <Card sx={{ p: 2, mt: 2 }}>
      <Typography variant="h6">Create a Post</Typography>

      {auth ? (
        <Typography variant="h6">
          <UserProfile firstName={auth.firstName} lastName={auth.lastName} />
        </Typography>
      ) : null}

      <TextField
        fullWidth
        multiline
        rows={3}
        placeholder="What's on your mind?"
        value={content}
        onChange={(e) => setContent(e.target.value)}
        sx={{ mb: 2 }}
      />

      <Typography variant="body2" sx={{ mb: 1 }}>
        {photos.length} / 10 photos selected
      </Typography>

      {/* Photo Upload */}
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="photo-upload"
        type="file"
        multiple
        onChange={handlePhotoChange}
        disabled={isPhotoUploadDisabled || isPosting}
      />
      <label htmlFor="photo-upload">
        <Button
          variant="outlined"
          component="span"
          startIcon={<PhotoCamera />}
          sx={{ mb: 2 }}
          disabled={isPhotoUploadDisabled || isPosting}
        >
          Add Photos
        </Button>
      </label>

      {/* Video Upload */}
      <input
        accept="video/*"
        style={{ display: 'none' }}
        id="video-upload"
        type="file"
        onChange={handleVideoChange}
        disabled={isVideoUploadDisabled || isPosting}
      />
      <label htmlFor="video-upload">
        <Button
          variant="outlined"
          component="span"
          startIcon={<VideoCall />}
          sx={{ mb: 2, ml: 2 }}
          disabled={isVideoUploadDisabled || isPosting}
        >
          Add Video
        </Button>
      </label>

      {/* Photo Previews */}
      {photos.length > 0 && (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 2 }}>
          {photos.map((photo, index) => (
            <Box key={index} sx={{ position: 'relative', m: 1 }}>
              <img
                src={URL.createObjectURL(photo)}
                alt="preview"
                style={{
                  width: 80,
                  height: 80,
                  borderRadius: 8,
                  objectFit: 'cover',
                }}
              />
              <IconButton
                onClick={() => removePhoto(index)}
                size="small"
                sx={{
                  position: 'absolute',
                  top: -5,
                  right: -5,
                  background: 'rgba(0,0,0,0.5)',
                  color: 'white',
                }}
              >
                <Delete fontSize="small" />
              </IconButton>
            </Box>
          ))}
        </Box>
      )}

      {/* Video Preview */}
      {videoPreview && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="body2">Selected Video:</Typography>
          <video
            width="100%"
            height="auto"
            controls
            style={{ maxWidth: '450px' }}
          >
            <source src={videoPreview} type={video?.type || 'video/mp4'} />
            Your browser does not support the video tag.
          </video>
          <Button variant="text" color="error" onClick={removeVideo}>
            Remove Video
          </Button>
        </Box>
      )}

      {/* Action Buttons */}
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{ mr: 2 }}
          onClick={handleSubmit}
          disabled={isPosting || (photos.length === 0 && !video && content.trim() === '')}
        >
          Post
        </Button>
        {isPosting && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CircularProgress size={24} sx={{ mr: 1 }} />
            <Typography variant="body2">Posting...</Typography>
          </Box>
        )}
        <Button variant="outlined" color="secondary" startIcon={<Cancel />} onClick={cancelPost}>
          Cancel
        </Button>
      </Box>
    </Card>
  );
}

// src/components/Landing.tsx
import React from 'react';
import { Button, Grid, Typography, Box } from '@mui/material';
import { ShoppingCart } from '@mui/icons-material';
import MediaFeed from './media/MediaFeed';
import AddMedia from './media/AddMedia';
import HelmetTracker from './core/HelmetTracker';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const Landing: React.FC = () => {
  const theme = useTheme();

  const getSaleHorseButton = (): JSX.Element => (
    <Button
      variant="contained"
      color="primary"
      startIcon={<ShoppingCart />}
      component={Link}
      to="/sales"
      sx={{ mt: 2 }}
    >
      Shop All Sales Horses
    </Button>
  );

  return (
    <>
      <HelmetTracker title="FGF Home" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              textAlign: 'center',
              bgcolor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              p: 3,
              borderRadius: '8px',
              boxShadow: '0px 4px 8px rgba(0,0,0,0.2)',
              mb: 2,
            }}
          >
            <Typography variant="h3" fontWeight="bold">
              Welcome to Full Gallop Farm
            </Typography>
          </Box>
          {getSaleHorseButton()}
        </Grid>
        <Grid item xs={12}>
          <AddMedia />
          <MediaFeed />
        </Grid>
      </Grid>
    </>
  );
};

export default Landing;

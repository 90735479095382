// src/components/customers/horses/pages/HorsesPage.tsx
import React, { useState } from 'react';
import {
  Box,
  Typography,
  Stack,
  Button,
  Card,
  CardContent,
  TextField,
//   useTheme,
} from '@mui/material';
import { PlusCircle, ListCheck } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import HelmetTracker from '../../../core/HelmetTracker';
import HorsesTable from './core/HorsesTable';
import _ from 'lodash';

export default function HorsesPage() {
  const [showAll, setShowAll] = useState(false);
  const [filterText, setFilterText] = useState('');
  const horses = useSelector((state: any) => state.horses.list);

  const filterArchivedHorses = (includeArchived: boolean) => {
    return includeArchived
      ? _.filter(horses, (o) => !o.dataStatus)
      : horses;
  };

  const filterHorses = (includeArchived: boolean) => {
    const list = filterArchivedHorses(includeArchived);
    return filterText.length > 0
      ? _.filter(list, (o) =>
          o.name?.toLowerCase().includes(filterText.toLowerCase())
        )
      : list;
  };

  return (
    <Box sx={{ p: 2 }}>
      <HelmetTracker title="Horses" />
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack direction="row" spacing={3} alignItems="center">
            <Typography variant="h4">Horses</Typography>
            <Button
              variant="contained"
              onClick={() => setShowAll((prev) => !prev)}
              startIcon={<ListCheck />}
              color="info"
            >
              {showAll ? 'Hide Archived' : 'Show All'}
            </Button>
          </Stack>
          <Stack direction="row" spacing={2} mt={2} flexWrap="wrap">
            <Button
              component={RouterLink}
              to="/horses/new"
              variant="contained"
              startIcon={<PlusCircle />}
            >
              Add Horse
            </Button>
            <Button
              component={RouterLink}
              to="/horses/feed"
              variant="contained"
              startIcon={<ListCheck />}
            >
              Feed List
            </Button>
          </Stack>
          <Stack direction="row" spacing={2} mt={2}>
            <Typography variant="subtitle2">
              Total Horses: {horses.length}
            </Typography>
            <Typography variant="subtitle2">
              Unarchived: {filterHorses(true).length}
            </Typography>
          </Stack>
          <TextField
            label="Search by Name"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            sx={{ mt: 2 }}
            fullWidth
          />
        </CardContent>
      </Card>

      <HorsesTable horses={filterHorses(!showAll)} />
    </Box>
  );
}
import { Post } from '../../classes/Post.class';

export const setLoading = (state: any) => {
  state.status = 'loading';
};

export const setSucceeded = (state: any) => {
  state.status = 'succeeded';
};

export const setFailed = (state: any, error: unknown) => {
  state.status = 'failed';
  state.error = typeof error === 'string' ? error : 'An error occurred';
};

export const prependPostToFeed = (feed: Post[], post: Post): Post[] => {
  return [post, ...feed];
};

import React, { useState } from 'react';
import { Box, Grid, CardMedia, Dialog, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function Gallery({ photos }) {
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleClickOpen = (index) => {
    setCurrentIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePrev = (e) => {
    e.stopPropagation();
    setCurrentIndex((prev) => (prev === 0 ? photos.length - 1 : prev - 1));
  };

  const handleNext = (e) => {
    e.stopPropagation();
    setCurrentIndex((prev) => (prev === photos.length - 1 ? 0 : prev + 1));
  };

  if (!photos || photos.length === 0) return null;

  // Determine the number of columns based on photo count
  const columns = photos.length === 1 ? 1 : photos.length === 2 ? 2 : 3;

  return (
    <>
      {/* Gallery Grid */}
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={1}>
          {photos.map((photo, index) => (
            <Grid item xs={12 / columns} key={photo.id}>
              <CardMedia
                component="img"
                image={photo.amazons3URLHalf} // Use thumbnail version for speed
                alt={photo.originalName || 'Photo'}
                onClick={() => handleClickOpen(index)}
                sx={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: 1,
                  cursor: 'pointer'
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Fullscreen Lightbox Modal */}
      <Dialog fullScreen open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'relative',
            backgroundColor: 'black',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClick={handleClose} // Clicking outside the image closes the modal
        >
          {/* Close Button */}
          <IconButton
            onClick={handleClose}
            sx={{ position: 'absolute', top: 16, right: 16, color: 'white' }}
          >
            <CloseIcon />
          </IconButton>
          {/* Navigation Buttons (if more than one photo) */}
          {photos.length > 1 && (
            <>
              <IconButton
                onClick={handlePrev}
                sx={{ position: 'absolute', left: 16, color: 'white' }}
              >
                <ArrowBackIosNewIcon />
              </IconButton>
              <IconButton
                onClick={handleNext}
                sx={{ position: 'absolute', right: 16, color: 'white' }}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </>
          )}
          {/* Full Image */}
          <Box
            component="img"
            src={photos[currentIndex].amazons3URL} // Use the full or medium version as desired
            alt={photos[currentIndex].originalName || 'Photo'}
            sx={{ maxHeight: '100%', maxWidth: '100%' }}
          />
        </Box>
      </Dialog>
    </>
  );
}

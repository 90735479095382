// src/redux/slices/posts/posts.slice.ts

import { createSlice } from '@reduxjs/toolkit';
import type { PostsState } from './posts.types';
import { fetchPosts, createPost } from './posts.thunks';
import {
  setLoading,
  setSucceeded,
  setFailed,
  prependPostToFeed,
} from './posts.helpers';

const initialState: PostsState = {
  feed: [],
  status: 'idle',
  error: null,
};

const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // FETCH POSTS
      .addCase(fetchPosts.pending, (state) => {
        setLoading(state);
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        setSucceeded(state);
        state.feed = action.payload;
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        setFailed(state, action.error.message);
      })

      // CREATE POST
      .addCase(createPost.fulfilled, (state, action) => {
        if (action.payload) {
          state.feed = prependPostToFeed(state.feed, action.payload);
        }
      });
  },
});

export default postsSlice.reducer;

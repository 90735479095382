import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { postToClass, postsToClassArray } from '../../classes/Post.class';

const API_URL = '/api/posts';
const CREATE_POST_API_URL = '/api/media/posts/upload';

export const fetchPosts = createAsyncThunk('posts/fetchAll', async () => {
  const response = await axios.get(API_URL);
  return postsToClassArray(response.data);
});

export const createPost = createAsyncThunk(
  'posts/create',
  async (formData: FormData) => {
    const response = await axios.post(CREATE_POST_API_URL, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return postToClass(response.data);
  }
);

// src/components/media/MediaFeed.tsx
import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

import { fetchPosts } from '../../redux/slices/posts/posts.thunks';
import MediaItemGroup from './MediaItemGroup';

const MediaFeed: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchPosts());
  }, [dispatch]);

  // Use proper RootState type if available; otherwise, we use any.
  const auth = useAppSelector((state: any) => state.auth);
  const postsFeed = useAppSelector((state: any) => state.posts.feed);

  return <MediaItemGroup mediaFeed={postsFeed} auth={auth} />;
};

export default MediaFeed;

// src/classes/Post.class.ts

import { Photo, photosToClassArray } from './Photo.class';
import { Video, videosToClassArray } from './Video.class';
import { S3Video, s3VideosToClassArray } from './S3Video.class';
import { Document, documentsToClassArray } from './Document.class';

export class Post {
  id: number;
  userId: number;
  content: string;
  type: number;
  dataStatus: number;
  createdAt: string;
  updatedAt: string;
  authorName: string;
  authorId: number;
  photos: Photo[] | null;
  videos: Video[] | null;
  documents: Document[] | null;
  s3videos: S3Video[] | null;

  constructor(
    id: number,
    userId: number,
    content: string,
    type: number,
    dataStatus: number,
    createdAt: string,
    updatedAt: string,
    authorName: string,
    authorId: number,
    photos: any,
    videos: any,
    documents: any,
    s3videos: any
  ) {
    this.id = id;
    this.userId = userId;
    this.content = content;
    this.type = type;
    this.dataStatus = dataStatus;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.authorName = authorName;
    this.authorId = authorId;
    this.photos = photos ? photosToClassArray(photos) : null;
    this.videos = videos ? videosToClassArray(videos) : null;
    this.documents = documents ? documentsToClassArray(documents) : null;
    this.s3videos = s3videos ? s3VideosToClassArray(s3videos) : null;
  }
}

export function postToClass(post: any): Post | null {
  if (post) {
    return new Post(
      post.id,
      post.userId,
      post.content,
      post.type,
      post.dataStatus,
      post.createdAt,
      post.updatedAt,
      post.authorName,
      post.authorId,
      post.photos,
      post.videos,
      post.documents,
      post.s3videos
    );
  }
  return null;
}

export function postsToClassArray(posts: any[]): Post[] {
  return posts.map((post) => postToClass(post)) as Post[];
}

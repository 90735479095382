// src/components/customers/horses/pages/core/HorsesTable.tsx
import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Stack,
  Typography,
  Chip,
  Avatar,
  Tooltip,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
  CardActionArea,
  Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

interface Horse {
  id: number;
  name: string;
  photo?: any;
  photos?: any[];
  videos?: any[];
  documents?: any[];
  gender?: number;
  breed?: string;
  color?: string;
  barn?: { id: number; name: string; barnOwnerName: string };
  isSaleHorse?: boolean;
  sold?: boolean;
  dataStatus?: number;
  pedigree?: any;
  hasPhoto?: () => boolean;
  getMainPhotoURLThumb?: () => string;
}

interface HorsesTableProps {
  horses: Horse[];
}

const StyledChip = styled(Chip)`
  height: 22px;
  font-weight: 700;
  font-size: 0.75rem;
`;

const getIdChip = (id: number, isArchived: boolean, isMobile: boolean) => (
  <StyledChip
    label={isMobile ? `Id: ${id}` : id}
    sx={{ backgroundColor: isArchived ? '#d32f2f' : '#2e7d32', color: '#fff' }}
  />
);

const getBarnChip = (barn?: { id: number; name: string }) => {
  if (!barn) return null;
  return (
    <Tooltip title={`Barn ID: ${barn.id}\n${barn.name}`} arrow>
      <StyledChip label={barn.name} color="secondary" />
    </Tooltip>
  );
};

const getSaleChip = (isSale: boolean, isSold?: boolean) => {
  if (isSold) return <StyledChip label="SOLD" color="secondary" />;
  return isSale ? (
    <StyledChip
      label="Sale"
      sx={{ backgroundColor: '#2e7d32', color: '#fff' }}
    />
  ) : null;
};

const getPedigreeChip = (pedigree: any) => {
  if (!pedigree || JSON.stringify(pedigree).toLowerCase().includes('unknown'))
    return null;
  return (
    <StyledChip
      label="Pedigree"
      sx={{ backgroundColor: '#0D47A1', color: '#fff' }}
    />
  );
};

const getGenderChip = (gender: number) => {
  switch (gender) {
    case 1:
      return (
        <StyledChip
          label="Gelding"
          sx={{ backgroundColor: '#0D47A1', color: '#fff' }}
        />
      );
    case 2:
      return <StyledChip label="Mare" color="secondary" />;
    case 3:
      return (
        <StyledChip
          label="Stallion"
          sx={{ backgroundColor: '#2e7d32', color: '#fff' }}
        />
      );
    default:
      return <StyledChip label="Unassigned" color="warning" />;
  }
};

const getPhotoCountChip = (count: number) =>
  count > 0 ? (
    <StyledChip label={`${count} Pictures`} color="secondary" />
  ) : null;

const getVideoCountChip = (count: number) =>
  count > 0 ? (
    <StyledChip
      label={`${count} Videos`}
      sx={{ backgroundColor: '#FF9800', color: '#000' }}
    />
  ) : null;

const getDocumentCountChip = (count: number) =>
  count > 0 ? (
    <StyledChip
      label={`${count} Documents`}
      sx={{ backgroundColor: '#0D47A1', color: '#fff' }}
    />
  ) : null;

const getHorsePhoto = (horse: Horse) => {
  const url =
    horse?.hasPhoto?.() && horse?.getMainPhotoURLThumb?.()
      ? horse.getMainPhotoURLThumb()
      : '/images/horses/horse_avatar_thumb.jpg';

  return (
    <Avatar
      src={url}
      alt={`Horse ${horse.name}`}
      variant="square"
      sx={{ width: 56, height: 56 }}
    />
  );
};

const HorsesTable: React.FC<HorsesTableProps> = ({ horses }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width:700px)');

  if (isMobile) {
    return (
      <Stack spacing={2}>
        {horses.map((horse) => (
          <Card
            key={horse.id}
            onClick={() => navigate(`/horses/manage/${horse.id}`)}
          >
            <CardActionArea>
              <CardContent>
                <Stack direction="row" spacing={2} alignItems="flex-start">
                  {getHorsePhoto(horse)}
                  <Box>
                    <Typography variant="h6" fontWeight="bold">
                      {horse.name}
                    </Typography>
                    <Stack direction="row" spacing={1} flexWrap="wrap" mt={1}>
                      {getIdChip(horse.id, !!horse.dataStatus, true)}
                      {getBarnChip(
                        horse.barn && {
                          id: horse.barn.id,
                          name: horse.barn.name,
                        }
                      )}
                      {getSaleChip(!!horse.isSaleHorse, horse.sold)}
                      {getPedigreeChip(horse.pedigree)}
                      {getGenderChip(horse.gender || 0)}
                    </Stack>
                    <Stack direction="row" spacing={1} flexWrap="wrap" mt={1}>
                      {getPhotoCountChip(horse.photos?.length || 0)}
                      {getVideoCountChip(horse.videos?.length || 0)}
                      {getDocumentCountChip(horse.documents?.length || 0)}
                      {horse.photo && (
                        <StyledChip
                          label="Upgraded"
                          sx={{ backgroundColor: '#2e7d32', color: '#fff' }}
                        />
                      )}
                    </Stack>
                    <Typography variant="body2" mt={1}>
                      {horse.breed} - {horse.color}
                    </Typography>
                  </Box>
                </Stack>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </Stack>
    );
  }

  return (
    <TableContainer
      component={Paper}
      sx={{
        backgroundColor: '#000',
        '& .MuiTableRow-root:nth-of-type(even)': {
          backgroundColor: '#1c1c1c',
        },
        '& .MuiTableCell-root': {
          color: '#fff',
        },
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Photo</TableCell>
            <TableCell>ID</TableCell>
            <TableCell>Sale</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Info</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {horses.map((horse) => (
            <TableRow
              key={horse.id}
              hover
              onClick={() => navigate(`/horses/manage/${horse.id}`)}
              sx={{ cursor: 'pointer' }}
            >
              <TableCell>{getHorsePhoto(horse)}</TableCell>
              <TableCell>
                <Stack spacing={1} alignItems="flex-start">
                  {getIdChip(horse.id, !!horse.dataStatus, false)}
                  {getBarnChip(
                    horse.barn && { id: horse.barn.id, name: horse.barn.name }
                  )}
                </Stack>
              </TableCell>
              <TableCell>
                <Stack spacing={1} alignItems="flex-start">
                  {getSaleChip(!!horse.isSaleHorse, horse.sold)}
                  {getPedigreeChip(horse.pedigree)}
                </Stack>
              </TableCell>
              <TableCell>
                <Typography fontWeight="bold" fontSize={'1.25rem'}>
                  {horse.name}
                </Typography>
                <Stack spacing={0.5} alignItems="flex-start">
                  {getPhotoCountChip(horse.photos?.length || 0)}
                  {getVideoCountChip(horse.videos?.length || 0)}
                  {getDocumentCountChip(horse.documents?.length || 0)}
                </Stack>
              </TableCell>
              <TableCell>
                <Stack spacing={0.5} alignItems="flex-start">
                  {getGenderChip(horse.gender || 0)}
                  <Typography variant="body2">{horse.breed}</Typography>
                  <Typography variant="body2">{horse.color}</Typography>
                  {horse.photo && (
                    <StyledChip
                      label="Upgraded"
                      sx={{ backgroundColor: '#2e7d32', color: '#fff' }}
                    />
                  )}
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HorsesTable;

// src/components/media/MediaItemGroup.tsx
import React from 'react';
import MediaItem from './MediaItem';
import { Box } from '@mui/material';
import { Post } from '../../redux/classes/Post.class';

interface MediaItemGroupProps {
  mediaFeed: Post[];
  auth: any;
}

const MediaItemGroup: React.FC<MediaItemGroupProps> = ({ mediaFeed, auth }) => {
  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
      {mediaFeed?.map((media, index) => (
        <MediaItem key={index} media={media} auth={auth} />
      ))}
    </Box>
  );
};

export default MediaItemGroup;
